type AssetItem = {
  ageOfAsset: string;
  airDirections: string[];
  apartmentNumber: string;
  assetType: string;
  beginningOfExclusivity: string;
  block: string;
  boiler: string[]; 
  description: string;
  elevators: string;
  email: string;
  endOfExclusivity: string;
  evacuationDate: string;
  features: ExtraItem[];
  files: string[]; 
  floor: string;
  floorOf: string;
  frontBack: string;
  fullName: string;
  furniture: string;
  gardenArea: string;
  id: string;
  location: string;
  lotSize: string;
  meterBuild: string;
  neighborhood: string;
  networkCooperation: boolean; // Boolean
  numberOfBathrooms: string;
  numberOfLevels: string;
  numberOfParkingSpaces: string;
  numberOfRooms: string;
  phone: string;
  price: string;
  roofSize: string;
  saleOrRent: string;
  settlement: string;
  sex: string;
  sizeOfBalconies: string;
  smooth: string;
  status: string;
  streetAndNumber: string;
  subdivision: string;
  videoLink: string;
  warehouseSize: string;
};

type PropertyFilter = {
  transactionType?: string;
  roomsFrom?: number;
  roomsTo?: number;
  cities?: string[];
  sqmFrom?: number;
  sqmTo?: number;
  neighborhoods?: string[];
  floorFrom?: number;
  floorTo?: number;
  assetTypes?: string[];
  description?: string;
  extras?: string[];
  priceFrom?: number;
  priceTo?: number;
  street?: string;
  customerName?: string;
  customerPhone?: string;
};

type BaseSelectOption = {
  id: string;
  name: string;
};

type City = BaseSelectOption;

type Neighborhood = BaseSelectOption;

type Extra = BaseSelectOption;

type Tag = BaseSelectOption & {
  color: string;
};

type INote = {
  id: string;
  note: string;
  createdAt: Date;
};

type Task = {
  id: string;
  text: string;
  status: TaskStatus;
  createdAt: Date;
};

type Document = {
  id: string;
  url: string;
  name: string;
  createdAt: Date;
};

type ClientMatch = {
  id: string;
  name: string;
  ratio: number;
};

type ICustomer = {
  id: string;
  fullName: string;
  sex: Sex;
  email: string;
  phone: string;
  description: string;
  address: string;
  preferences: PropertyFilter | null;
  groupIds: string[];
  registeredAt: Date;
  type: CustomerType;
  roomsFrom: string,
  roomsTo: string,
  sqmFrom: string,
  sqmTo: string,
  floorFrom: string,
  floorTo: string,
  priceFrom: string,
  priceTo: string,
};

type SignedClientMatch = {
  id: string;
  name: string;
};

export enum Sex {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export enum TaskStatus {
  New = "0",
  InProgress = "1",
  Completed = "2",
  Canceled = "3",
}

export enum ExtraItem {
  Elevators = 1,
  Bars = 2,
  AirConditioner = 3,
  AccessForDisabled = 4,
  Renovation = 5,
  NewDoors = 6,
  Parking = 7,
  Storage = 8,
  WiFi = 9,
}

export enum AssetType {
  PrivateAssets = 1,
  MediationAssets = 2,
  CommercialAssets = 3,
  MyAssets = 4,
  OfficeAssets = 5,
  TrackingAssets = 6,
  MLS = 7,
  Archive = 8,
}

export enum CustomerType {
  Sellers = 1,
  Buyers = 2,
  Leads = 3,
}

type Agency = {
  officeName: string;
  phone: string;
  email: string;
  mobile: string;
  fax: string;
  maxAgents?: number;
  specialization: string;
  address: string;
  avgPrices: string;
  hfEmNumber: string;
  description: string;
  officeLogo: any;
};

interface SellerDetails {
  agentAffiliation: string;
  fullName: string;
  email: string;
  sex: string;
  phone: string;
  location: string;
  id: string;
}

interface PropertyDetails {
  saleOrRent: string;
  status: string;
  assetType: string;
  evacuationDate: string;
  streetAndNumber: string;
  settlement: string;
  neighborhood: string;
  apartmentNumber: string;
  numberOfRooms: number;
  floor: number;
  floorOf: number;
  lotSize: string;
  meterBuild: string;
  gardenArea: string;
  roofSize: number;
  price: number;
}

interface CalendarEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  desc?: string;
  allDay?: boolean;
}

interface IDictionary<T> {
  [key: string]: T;
}

export type {
  AssetItem,
  Tag,
  PropertyFilter,
  City,
  Neighborhood,
  Extra,
  BaseSelectOption,
  INote,
  Task,
  Document,
  ClientMatch,
  SignedClientMatch,
  Agency,
  SellerDetails,
  PropertyDetails,
  ICustomer,
  CalendarEvent,
  IDictionary,
};

export enum MasterCardQuickActions {
  Tag,
  Notes,
  Follow,
  Task,
  Share,
}

export enum CustomerCardQuickActions {
  Tag,
  Notes,
  Tasks,
  Delete,
}
