export enum Pages {
  Login = "/login",
  Logout = "/logout",
  Assets = "/",
  Asset = "/asset",
  Customers = "/customers",
  Customer = "/customer",
  Calendar = "/calendar",
  Documents = "/documents",
  RegisterAgency = "/agency/new",
  CreateAsset = "/asset/new",
  CreateCustomer = "/customers/new",
}
