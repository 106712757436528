import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./pages/Login/reducer";
import assetsReducer from "./pages/Assets/reducer";
import customerReducer from "./pages/Customers/reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  assets: assetsReducer,
  customer: customerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
