export const GROUPS = [
  { label: 'Investor up to 1.5m', id: '1' },
  { label: 'Only commercial', id: '2' },
]

export const TAGS_MOCK: string[] = [
  "Luxury Property",
  "Commercial Property",
  "Foreclosed",
  "Renovation Required",
  "High-Investment Potential",
  "Seller Motivated",
  "First-time Buyer",
  "Cash Buyer",
  "Multi-family Units",
  "Prime Location",
  "Off-market",
  "Under Market Value",
  "Owner-Occupied",
  "Investment Property",
  "Distressed Property",
  "Vacant Land",
  "Short Sale",
  "Pre-foreclosure",
  "Fixer-Upper",
  "Turnkey Property",
  "Seller Financing",
  "Buyer Pre-approved",
  "Historic Property",
  "New Construction",
  "Under Contract",
  "Pending Sale",
  "Price Reduced",
  "New Listing",
  "Long-Term Rental",
  "Vacation Home",
  "Corporate Seller",
  "Real Estate Owned (REO)",
  "Bank-Owned",
  "Zoned for Development",
  "Residential Property",
  "Industrial Property",
  "Retail Property",
  "Agricultural Land",
  "Waterfront Property",
  "Mountain Property",
  "Gated Community",
  "Golf Course Access",
  "High-End Amenities",
  "Eco-friendly",
  "Smart Home",
  "Energy Efficient",
  "Green Building",
  "Property Managed",
  "Vacancy",
  "Leased",
  "Short-Term Rental",
  "Sublease",
  "Co-living",
  "Joint Ownership",
  "HOA Fees",
  "Pet-friendly",
  "Furnished",
  "Unfurnished",
  "Leasehold Property",
  "Freehold Property",
  "Retirement Community",
  "International Buyer",
  "Auction",
  "Corporate Buyer",
  "Lease Option",
  "Rent-to-Own",
  "Tenant-Occupied",
  "Rural Property",
  "Urban Property",
  "Expired Listing"
];

export const PRIVATE_ASSETS_MOCK = [
  {
    ageOfAsset: "22",
    airDirections: ["south"],
    apartmentNumber: "33",
    assetType: "duplex",
    beginningOfExclusivity: "1990",
    block: "12",
    boiler: ["sun"],
    description: "Good apartment and more",
    elevators: "2",
    email: "bernhard@kaligon.com",
    endOfExclusivity: "2000",
    evacuationDate: "14.01.1994",
    features: [3, 2, 1],
    files: ["fake-url", "fake-url", "fake-url"],
    floor: "22343",
    floorOf: "3",
    frontBack: "hinterland",
    fullName: "John fsdsdfdsfdf",
    furniture: "partial",
    gardenArea: "23",
    id: "1234",
    location: "france",
    lotSize: "332",
    meterBuild: "2332",
    neighborhood: "nice",
    networkCooperation: false,
    numberOfBathrooms: "11",
    numberOfLevels: "3",
    numberOfParkingSpaces: "22",
    numberOfRooms: "3232",
    phone: "123232",
    price: "1000000",
    roofSize: "22",
    saleOrRent: "rent",
    settlement: "parisdsf",
    sex: "female",
    sizeOfBalconies: "3",
    smooth: "yes",
    status: "sold",
    streetAndNumber: "good street 2",
    subdivision: "Right",
    videoLink: "no",
    warehouseSize: "2",
  },
  {
    ageOfAsset: "10",
    airDirections: ["north"],
    apartmentNumber: "10",
    assetType: "apartment",
    beginningOfExclusivity: "2015",
    block: "7",
    boiler: ["electric"],
    description: "Spacious apartment",
    elevators: "1",
    email: "alice@realtyco.com",
    endOfExclusivity: "2020",
    evacuationDate: "12.05.2017",
    features: [4, 2, 1],
    files: ["url1", "url2", "url3"],
    floor: "5",
    floorOf: "10",
    frontBack: "front",
    fullName: "Alice Doe",
    furniture: "fully furnished",
    gardenArea: "50",
    id: "2345",
    location: "spain",
    lotSize: "500",
    meterBuild: "3000",
    neighborhood: "central",
    networkCooperation: true,
    numberOfBathrooms: "3",
    numberOfLevels: "1",
    numberOfParkingSpaces: "2",
    numberOfRooms: "5",
    phone: "987654321",
    price: "500000",
    roofSize: "15",
    saleOrRent: "sale",
    settlement: "madrid",
    sex: "male",
    sizeOfBalconies: "10",
    smooth: "no",
    status: "available",
    streetAndNumber: "Main St 5",
    subdivision: "Left",
    videoLink: "yes",
    warehouseSize: "1"
  },
  {
    ageOfAsset: "5",
    airDirections: ["east"],
    apartmentNumber: "21",
    assetType: "condo",
    beginningOfExclusivity: "2018",
    block: "3",
    boiler: ["gas"],
    description: "Modern condo with great view",
    elevators: "3",
    email: "bob@condosales.com",
    endOfExclusivity: "2022",
    evacuationDate: "10.12.2020",
    features: [5, 3, 2],
    files: ["doc1", "doc2"],
    floor: "11",
    floorOf: "15",
    frontBack: "back",
    fullName: "Bob Smith",
    furniture: "unfurnished",
    gardenArea: "10",
    id: "3456",
    location: "italy",
    lotSize: "350",
    meterBuild: "2500",
    neighborhood: "suburban",
    networkCooperation: false,
    numberOfBathrooms: "2",
    numberOfLevels: "1",
    numberOfParkingSpaces: "1",
    numberOfRooms: "6",
    phone: "1122334455",
    price: "750000",
    roofSize: "30",
    saleOrRent: "rent",
    settlement: "rome",
    sex: "female",
    sizeOfBalconies: "8",
    smooth: "yes",
    status: "rented",
    streetAndNumber: "via della pace 12",
    subdivision: "Center",
    videoLink: "yes",
    warehouseSize: "5"
  },
  {
    ageOfAsset: "12",
    airDirections: ["west"],
    apartmentNumber: "44",
    assetType: "house",
    beginningOfExclusivity: "2008",
    block: "5",
    boiler: ["solar"],
    description: "Beautiful family house",
    elevators: "none",
    email: "carol@housemarket.com",
    endOfExclusivity: "2012",
    evacuationDate: "15.06.2011",
    features: [7, 5, 2],
    files: ["image1", "image2"],
    floor: "2",
    floorOf: "2",
    frontBack: "front",
    fullName: "Carol Johnson",
    furniture: "partial",
    gardenArea: "150",
    id: "4567",
    location: "germany",
    lotSize: "600",
    meterBuild: "4000",
    neighborhood: "quiet",
    networkCooperation: true,
    numberOfBathrooms: "4",
    numberOfLevels: "2",
    numberOfParkingSpaces: "4",
    numberOfRooms: "10",
    phone: "6677889900",
    price: "900000",
    roofSize: "40",
    saleOrRent: "sale",
    settlement: "berlin",
    sex: "male",
    sizeOfBalconies: "12",
    smooth: "no",
    status: "for sale",
    streetAndNumber: "Green Lane 15",
    subdivision: "West",
    videoLink: "no",
    warehouseSize: "3"
  },
  {
    ageOfAsset: "8",
    airDirections: ["southwest"],
    apartmentNumber: "102",
    assetType: "penthouse",
    beginningOfExclusivity: "2013",
    block: "8",
    boiler: ["electric"],
    description: "Luxury penthouse with rooftop pool",
    elevators: "2",
    email: "david@luxuryrealty.com",
    endOfExclusivity: "2019",
    evacuationDate: "01.02.2016",
    features: [10, 8, 3],
    files: ["lux-url1", "lux-url2"],
    floor: "20",
    floorOf: "21",
    frontBack: "back",
    fullName: "David Lee",
    furniture: "fully furnished",
    gardenArea: "90",
    id: "5678",
    location: "dubai",
    lotSize: "1200",
    meterBuild: "8000",
    neighborhood: "elite",
    networkCooperation: true,
    numberOfBathrooms: "6",
    numberOfLevels: "2",
    numberOfParkingSpaces: "6",
    numberOfRooms: "12",
    phone: "5544332211",
    price: "5000000",
    roofSize: "100",
    saleOrRent: "sale",
    settlement: "jumeirah",
    sex: "female",
    sizeOfBalconies: "25",
    smooth: "yes",
    status: "available",
    streetAndNumber: "Palm Jumeirah 1",
    subdivision: "South",
    videoLink: "yes",
    warehouseSize: "10"
  },
  {
    ageOfAsset: "15",
    airDirections: ["northwest"],
    apartmentNumber: "75",
    assetType: "villa",
    beginningOfExclusivity: "2005",
    block: "4",
    boiler: ["gas"],
    description: "Luxurious villa with private garden",
    elevators: "none",
    email: "ellen@villasales.com",
    endOfExclusivity: "2010",
    evacuationDate: "20.10.2008",
    features: [8, 6, 3],
    files: ["villa-url1", "villa-url2"],
    floor: "3",
    floorOf: "3",
    frontBack: "front",
    fullName: "Ellen White",
    furniture: "unfurnished",
    gardenArea: "200",
    id: "6789",
    location: "italy",
    lotSize: "700",
    meterBuild: "5000",
    neighborhood: "coastal",
    networkCooperation: false,
    numberOfBathrooms: "5",
    numberOfLevels: "2",
    numberOfParkingSpaces: "4",
    numberOfRooms: "15",
    phone: "9988776655",
    price: "4000000",
    roofSize: "50",
    saleOrRent: "sale",
    settlement: "naples",
    sex: "male",
    sizeOfBalconies: "30",
    smooth: "no",
    status: "sold",
    streetAndNumber: "Coastal Drive 18",
    subdivision: "East",
    videoLink: "no",
    warehouseSize: "7"
  },
  {
    ageOfAsset: "9",
    airDirections: ["east"],
    apartmentNumber: "45",
    assetType: "townhouse",
    beginningOfExclusivity: "2014",
    block: "9",
    boiler: ["solar"],
    description: "Cozy townhouse near park",
    elevators: "none",
    email: "frank@townhousesales.com",
    endOfExclusivity: "2020",
    evacuationDate: "05.07.2018",
    features: [5, 4, 2],
    files: ["url3", "url4"],
    floor: "2",
    floorOf: "2",
    frontBack: "back",
    fullName: "Frank Green",
    furniture: "partially furnished",
    gardenArea: "100",
    id: "7890",
    location: "canada",
    lotSize: "400",
    meterBuild: "2000",
    neighborhood: "urban",
    networkCooperation: true,
    numberOfBathrooms: "3",
    numberOfLevels: "2",
    numberOfParkingSpaces: "3",
    numberOfRooms: "8",
    phone: "2233445566",
    price: "800000",
    roofSize: "35",
    saleOrRent: "rent",
    settlement: "toronto",
    sex: "female",
    sizeOfBalconies: "15",
    smooth: "yes",
    status: "available",
    streetAndNumber: "Park Lane 9",
    subdivision: "North",
    videoLink: "yes",
    warehouseSize: "6"
  },
  {
    ageOfAsset: "20",
    airDirections: ["south"],
    apartmentNumber: "12B",
    assetType: "bungalow",
    beginningOfExclusivity: "2000",
    block: "2",
    boiler: ["electric"],
    description: "Charming bungalow with pool",
    elevators: "none",
    email: "george@bungalowsales.com",
    endOfExclusivity: "2005",
    evacuationDate: "10.11.2003",
    features: [6, 4, 3],
    files: ["bungalow-url1", "bungalow-url2"],
    floor: "1",
    floorOf: "1",
    frontBack: "front",
    fullName: "George Stone",
    furniture: "fully furnished",
    gardenArea: "120",
    id: "8901",
    location: "australia",
    lotSize: "500",
    meterBuild: "3500",
    neighborhood: "suburban",
    networkCooperation: false,
    numberOfBathrooms: "4",
    numberOfLevels: "1",
    numberOfParkingSpaces: "2",
    numberOfRooms: "7",
    phone: "5566778899",
    price: "1500000",
    roofSize: "25",
    saleOrRent: "sale",
    settlement: "sydney",
    sex: "male",
    sizeOfBalconies: "10",
    smooth: "no",
    status: "rented",
    streetAndNumber: "Beach Road 3",
    subdivision: "West",
    videoLink: "no",
    warehouseSize: "4"
  },
  {
    ageOfAsset: "18",
    airDirections: ["west"],
    apartmentNumber: "60",
    assetType: "studio",
    beginningOfExclusivity: "2003",
    block: "1",
    boiler: ["gas"],
    description: "Compact studio in the city center",
    elevators: "1",
    email: "helen@studioagency.com",
    endOfExclusivity: "2008",
    evacuationDate: "01.06.2006",
    features: [2, 1, 1],
    files: ["studio-url1"],
    floor: "8",
    floorOf: "10",
    frontBack: "front",
    fullName: "Helen Brown",
    furniture: "unfurnished",
    gardenArea: "5",
    id: "9012",
    location: "uk",
    lotSize: "150",
    meterBuild: "1200",
    neighborhood: "downtown",
    networkCooperation: true,
    numberOfBathrooms: "1",
    numberOfLevels: "1",
    numberOfParkingSpaces: "1",
    numberOfRooms: "2",
    phone: "6677889900",
    price: "400000",
    roofSize: "12",
    saleOrRent: "rent",
    settlement: "london",
    sex: "female",
    sizeOfBalconies: "5",
    smooth: "yes",
    status: "available",
    streetAndNumber: "Oxford St 20",
    subdivision: "South",
    videoLink: "yes",
    warehouseSize: "2"
  },
  {
    ageOfAsset: "25",
    airDirections: ["north"],
    apartmentNumber: "88",
    assetType: "mansion",
    beginningOfExclusivity: "1995",
    block: "10",
    boiler: ["solar"],
    description: "Grand mansion with private lake",
    elevators: "none",
    email: "ian@mansionluxury.com",
    endOfExclusivity: "2000",
    evacuationDate: "01.04.1998",
    features: [12, 10, 6],
    files: ["mansion-url1", "mansion-url2"],
    floor: "3",
    floorOf: "3",
    frontBack: "front",
    fullName: "Ian Forbes",
    furniture: "fully furnished",
    gardenArea: "300",
    id: "9021",
    location: "usa",
    lotSize: "2000",
    meterBuild: "12000",
    neighborhood: "exclusive",
    networkCooperation: false,
    numberOfBathrooms: "7",
    numberOfLevels: "3",
    numberOfParkingSpaces: "10",
    numberOfRooms: "20",
    phone: "9998887777",
    price: "10000000",
    roofSize: "200",
    saleOrRent: "sale",
    settlement: "beverly hills",
    sex: "male",
    sizeOfBalconies: "50",
    smooth: "yes",
    status: "sold",
    streetAndNumber: "Sunset Blvd 50",
    subdivision: "North",
    videoLink: "no",
    warehouseSize: "15"
  }
]