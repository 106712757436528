import AuthService from "./authService";
import CustomerService from "./customerService";
import CalendarService from "./calendarService";
import CityService from "./cityService";
import DocumentService from "./documentService";
import ExtraService from "./extraService";
import LocalStorageService from "./localStorage";
import NeighborhoodService from "./neighborhoodService";
import NotesService from "./notesService";
import AssetService from "./assetService";
import TagService from "./tagService";
import TasksService from "./tasksService";

export default class ServiceProvider {
  static Auth = new AuthService();
  static Tag = new TagService();
  static Property = new AssetService();
  static City = new CityService();
  static Neighborhood = new NeighborhoodService();
  static Extra = new ExtraService();
  static Note = new NotesService();
  static Task = new TasksService();
  static Document = new DocumentService();
  static Customer = new CustomerService();
  static Calendar = new CalendarService();
  static LocalStorage = new LocalStorageService();
}
