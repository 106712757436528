import { PRIVATE_ASSETS_MOCK } from "../consts";
import {
  AssetItem,
  PropertyFilter,
  ClientMatch,
  SignedClientMatch,
  BaseSelectOption,
  AssetType,
} from "../types";
import { getResultsByAssetType } from "../utils/getResultsByAssetType";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class AssetService extends BaseService {
  constructor() {
    super("property");
  }

  async getAssetTypes(): Promise<BaseSelectOption[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "Garage",
            },
            {
              id: "2",
              name: "House",
            },
          ]),
        1000
      );
    });
  }

  async getClientMatches(propertyId: string): Promise<{
    clientMatch: ClientMatch[];
    signedClientMatch: SignedClientMatch[];
  }> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            clientMatch: [
              {
                id: "1",
                name: "Paul Pogba",
                ratio: 0.72,
              },
              {
                id: "1",
                name: "Erling Holand",
                ratio: 0.44,
              },
              {
                id: "3",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "4",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "5",
                name: "Clarence Seedorf",
                ratio: 0.67,
              },
            ],
            signedClientMatch: [
              {
                id: "1",
                name: "Arnold Dlonra",
              },
            ],
          }),
        1000
      );
    });
  }

  async getAll(
    assetType: AssetType,
    filter: PropertyFilter | null
  ): Promise<{ count: number; results: AssetItem[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = getResultsByAssetType(assetType);
        resolve({
          count: results.length,
          results,
        });
      }, 1000);
    });
  }
}
