import BaseService from "./baseService";

export default class AuthService extends BaseService {
  constructor() {
    super("");
  }

  async login(emailAddress: string, password: string) {
    return super.post("", {
      query: `mutation($emailAddress: String!, $password: String!){
        login(emailAddress: $emailAddress, password: $password) {
          message
          token
          profile {
            Id
            brokerDetails {
              address {
                city
                country
              }
              israelIdNumber
              licenceNumber
            }
            emailAddress
            firstName
            lastName
            phoneNumber
            role
            address {
              city
              country
            }
            avatarUrl
          }
        }
      }`,
      variables: {
        emailAddress,
        password,
      },
    });
  }

  async logout() {
    return new Promise((resolve) => {
      setTimeout(() => resolve(1), 1000);
    });
  }

  async getProfile() {
    return super.post("", {
      query: `query {
        profile {
          Id
          brokerDetails {
            address {
              city
              country
            }
            israelIdNumber
            licenceNumber
          }
          emailAddress
          firstName
          lastName
          phoneNumber
          role
          address {
            city
            country
          }
          avatarUrl
        }
      }`,
    });
  }
}
