import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "../../services/provider";
import { getError } from "../../utils/errors";
import { ICustomer } from "../../types";

interface CustomersData {
  data: ICustomer[];
  count: number;
  loading: boolean;
}

export interface CustomersStore {
  items: CustomersData;
  error: string | null;
}

const initialState: CustomersStore = {
  items: { data: [], count: 0, loading: false },
  error: null,
};

export const getCustomersDataSourceAsync = createAsyncThunk(
  "customer/getCustomerItems",
  async () => {
    return await ServiceProvider.Customer.getAll();
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getCustomersDataSourceAsync.fulfilled, (state, action) => {
        const response = get(action, "payload", {
          count: 0,
          results: [],
        });
        state.items.data = response.results;
        state.items.count = response.count;
        state.items.loading = false;
      })
      .addCase(getCustomersDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.loading = false;
        state.error = getError(action);
      });
  },
});

export const makeCustomerDataSource = (state: { customer: CustomersStore }) => {
  return state.customer.items;
}

export default customerSlice.reducer;
