import { ToastMessage } from "../components/ToastProvider/ToastProvider";
import BaseModel from "../models/base";
import { IDictionary } from "../types";

export const validateModel = (model: BaseModel, rules?: IDictionary<any>) => {
  const validation = model.validate(rules);
  const isModelValid = Object.keys(validation).length === 0;

  console.log(model)

  let errors: ToastMessage[] = [];
  if (!isModelValid) {
    errors = Object.keys(validation).map((m) => ({
      type: "warning",
      message: validation[m][0],
    }));
  }

  return { isModelValid, errors };
};
