import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "../../services/provider";
import { getError } from "../../utils/errors";
import {
  City,
  Extra,
  Neighborhood,
  PropertyFilter,
  AssetItem,
  Tag,
  BaseSelectOption,
  AssetType,
} from "../../types";

export interface AssetsDataSource {
  data: AssetItem[];
  count: number;
  loading: boolean;
}

export interface AssetsStore {
  filter: PropertyFilter | null;
  items: AssetsDataSource;
  error: string | null;
  options: {
    tags: Tag[];
    assetTypes: BaseSelectOption[];
    cities: City[];
    neighborhoods: Neighborhood[];
    extras: Extra[];
    loading: boolean;
  };
}

const initialState: AssetsStore = {
  filter: null,
  items: { data: [], count: 0, loading: false },
  error: null,
  options: {
    tags: [],
    assetTypes: [],
    cities: [],
    neighborhoods: [],
    extras: [],
    loading: true,
  },
};

export const getFilterOptionsAsync = createAsyncThunk(
  "property/filterOptions",
  async (f) => {
    return Promise.all([
      ServiceProvider.Tag.getAll(),
      ServiceProvider.Property.getAssetTypes(),
      ServiceProvider.City.getAll(),
      ServiceProvider.Neighborhood.getAll(),
      ServiceProvider.Extra.getAll(),
    ]);
  }
);

interface IGetAssetsDataSource {
  assetType: AssetType,
  filter: PropertyFilter | null,
}

export const getAssetsDataSourceAsync = createAsyncThunk(
  "property/getAssetsItems",
  async ({
    assetType,
    filter
  }: IGetAssetsDataSource, thunkAPI) => {
    thunkAPI.dispatch(setFilter(filter));
    return await ServiceProvider.Property.getAll(assetType, filter);
  }
);

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilterOptionsAsync.pending, (state) => {
        state.options.loading = true;
      })
      .addCase(getFilterOptionsAsync.fulfilled, (state, action) => {
        const data = get(action, "payload", null);
        state.options.tags = data ? data[0] : [];
        state.options.assetTypes = data ? data[1] : [];
        state.options.cities = data ? data[2] : [];
        state.options.neighborhoods = data ? data[3] : [];
        state.options.extras = data ? data[4] : [];
        state.options.loading = false;
      })
      .addCase(getFilterOptionsAsync.rejected, (state, action) => {
        state.options = {
          tags: [],
          assetTypes: [],
          cities: [],
          neighborhoods: [],
          extras: [],
          loading: false,
        };
        state.options.loading = false;
        state.error = getError(action);
      })
      .addCase(getAssetsDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getAssetsDataSourceAsync.fulfilled, (state, action) => {
        const response = get(action, "payload", {
          count: 0,
          results: [],
        });
        state.items.data = response.results;
        state.items.count = response.count;
        state.items.loading = false;
      })
      .addCase(getAssetsDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.count = 0;
        state.items.loading = false;
        state.error = getError(action);
      });
  },
});

export const { setFilter } = assetsSlice.actions;

export const makeAssetsFilter = (state: { assets: AssetsStore }) =>
  state.assets.filter;

export const makeFilterOptions = (state: { assets: AssetsStore }) =>
  state.assets.options;

export const makeAssetsDataSource = (state: { assets: AssetsStore }) =>
  state.assets.items;

export default assetsSlice.reducer;
