import { Document } from "../types";
import BaseService from "./baseService";

export default class DocumentService extends BaseService {
  constructor() {
    super("documents");
  }

  async getPropertyAll(propertyId: string): Promise<Document[]> {
    return new Promise((resolve) => {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setHours(now.getHours() + 1);
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              url: "https://file-examples.com/storage/fe4e1227086659fa1a24064/2017/10/file-sample_150kB.pdf",
              name: "File Sample 1",
              createdAt: now,
            },
            {
              id: "2",
              url: "https://file-examples.com/storage/fe4e1227086659fa1a24064/2017/10/file-sample_150kB.pdf",
              name: "File Sample 2",
              createdAt: futureDate,
            },
          ]),
        1000
      );
    });
  }

  async getDocumentsAll(buyerId: string): Promise<Document[]> {
    return new Promise((resolve) => {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setHours(now.getHours() + 1);
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              url: "https://file-examples.com/storage/fe4e1227086659fa1a24064/2017/10/file-sample_150kB.pdf",
              name: "File Sample 1",
              createdAt: now,
            },
            {
              id: "2",
              url: "https://file-examples.com/storage/fe4e1227086659fa1a24064/2017/10/file-sample_150kB.pdf",
              name: "File Sample 2",
              createdAt: futureDate,
            },
          ]),
        1000
      );
    });
  }
}
