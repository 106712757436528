import { ICustomer } from "../types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class CustomerService extends BaseService {
  constructor() {
    super("customer");
  }

  async getAll(): Promise<{ count: number; results: ICustomer[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Customers) ?? [];
        resolve({
          count: results.length,
          results,
        });
      }, 1000);
    });
  }
}
