import { PRIVATE_ASSETS_MOCK } from "../consts";
import {
  AssetType,
} from "../types";
import { LocalStorageKeys } from "../services/localStorage";
import ServiceProvider from "../services/provider";

export const getResultsByAssetType = (assetType: AssetType) => {
  switch (assetType) {
  case AssetType.PrivateAssets: 
    return PRIVATE_ASSETS_MOCK;
  case AssetType.MediationAssets: 
    return PRIVATE_ASSETS_MOCK;
  case AssetType.CommercialAssets: 
    return PRIVATE_ASSETS_MOCK;
  case AssetType.MyAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.MyAssets) ?? [];
  case AssetType.OfficeAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.OfficeAssets) ?? [];
  case AssetType.TrackingAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.TrackingAssets) ?? [];
  default:
    return []
  }
}