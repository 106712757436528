const LS_PREFIX = "weshare";

class LocalStorageService {
  setItem = (key: string, value: any) => {
    const fullKey = this.getKey(key);
    window.localStorage.setItem(fullKey, JSON.stringify(value));
  };

  getItem = (key: string, defaultValue = null) => {
    const fullKey = this.getKey(key);
    const item = window.localStorage.getItem(fullKey);

    return item ? JSON.parse(item) : defaultValue;
  };

  removeItem = (key: string) => {
    const fullKey = this.getKey(key);
    window.localStorage.removeItem(fullKey);
  };

  getKey = (key: string) => {
    return `${LS_PREFIX}:${key}`;
  };
}

export enum LocalStorageKeys {
  Token = "token",
  MyAssets = "myassets",
  OfficeAssets = "officeassets",
  TrackingAssets = "trackingassets",
  Subscriptions = "subscriptions",
  Tags = "tags",
  Notes = "notes",
  Tasks = "tasks",
  Customers = "customers",
  LocalAssetsEdits = "localassetsedits"
}

export default LocalStorageService;
