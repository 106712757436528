import validate from "validate.js";
import { IDictionary } from "../types";

validate.validators.passwordConfirmation = function (
  value: any,
  options: { field: string; message: string },
  key: string,
  attributes: { [key: string]: any }
): string | undefined {
  if (value !== attributes[options.field]) {
    return options.message;
  }
  return undefined;
};

validate.validators.passwordComplexity = function (
  value: string,
  options: { message: string }
): string | null {
  if (!value) {
    return options.message || "Password cannot be empty";
  }

  if (value.length < 8 || value.length > 32) {
    return options.message || "Password must be between 8 and 32 characters";
  }

  if (!/[A-Z]/.test(value)) {
    return options.message || "Password must include an uppercase character";
  }

  if (!/\d/.test(value)) {
    return options.message || "Password must include a number";
  }

  return null;
};

class BaseModel {
  validationMsgs: IDictionary<string>;
  validationRules: IDictionary<any>;

  constructor(msg: IDictionary<string>) {
    this.validationMsgs = msg;
    this.validationRules = {};
  }

  validate = (
    validationRules: IDictionary<any> = {}
  ): { [key: string]: any } => {
    const rules =
      Object.keys(this.validationRules).length === 0
        ? validationRules
        : this.validationRules;
    const validation =
      Object.keys(rules).length === 0
        ? {}
        : validate(this, rules, { fullMessages: false });

    return validation || {};
  };
}

export default BaseModel;
